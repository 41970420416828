<template>
  <el-row class="row-center">
    <el-col class="col">
      <Title2
        :title="title"
        :label="label"
        :btnText="btnText"
        v-on:before-submit="beforeSubmit"/>
      <div class="profile row">
        <div class="left">
          <el-container>
            <el-main>
              <p style="color:#6a41bdb6;font-weight:bold;font-size:18px!important;margin:0px;">
                DATOS GENERALES
              </p>
              <el-form
                :model="form"
                ref="refId"
                :rules="rules"
                @keyup.enter.native="beforeSubmit()"
                @submit.prevent>
                <div class="row">
                  <el-form-item prop="firstName" label="Nombres">
                    <el-input v-model="form.firstName" :disabled="true" />
                  </el-form-item>
                  <el-form-item
                    prop="lastName"
                    label="Apellidos"
                    style="padding-left:5%;padding-right:0%!important;">
                    <el-input v-model="form.lastName" :disabled="true" />
                  </el-form-item>
                </div>
                <div class="row">
                  <el-form-item
                    prop="birthdate"
                    label="Fecha de nacimiento (aaaa-mm-dd)">
                    <el-input v-model="form.birthdate" :disabled="true" />
                  </el-form-item>
                  <el-form-item
                    prop="curp"
                    style="padding-left:5%;padding-right:0%!important;"
                    label="CURP">
                    <el-input
                      v-model="form.curp"
                      @input="upper($event)"
                      :disabled="true" />
                  </el-form-item>
                </div>
                <div class="row">
                  <el-form-item prop="phone" label="Teléfono Fijo">
                    <el-input v-model="form.phone" :disabled="disabled"/>
                  </el-form-item>
                  <el-form-item
                    prop="cellPhone"
                    label="Teléfono Particular"
                    style="padding-left:5%;padding-right:0%!important;">
                    <el-input v-model="form.cellPhone" :disabled="disabled"/>
                  </el-form-item>
                </div>
                <div class="row">
                  <el-form-item prop="extraPhone" label="Teléfono Extra">
                    <el-input v-model="form.extraPhone" disabled="disabled" />
                  </el-form-item>
                </div>
                <el-divider/>
                <p style="color:#6a41bdb6;font-weight:bold;font-size:18px!important;margin:0px;">
                  CITA SUBSECUENTE
                </p>
                <div class="row">
                  <div
                    v-if="false"
                    class="el-form-item is-required"
                    style="padding-right:0%!important;"
                  >
                    <label class="el-form-item__label" style="width: 100%;text-align:left">
                      Área
                    </label>
                    <div class="el-form-item__content">
                      <el-select
                        v-model="selectedRole"
                        :default-first-option="true"
                      >
                        <el-option
                          v-for="role in roles"
                          :key="role.id"
                          :label="role.name"
                          :value="role.id"
                        />
                      </el-select>
                    </div>
                  </div>
                  <el-form-item
                    v-if="false"
                    prop="userId"
                    label="Especialista"
                    style="padding-left:5%;padding-right:0%!important;"
                  >
                    <el-select v-model="form.userId">
                      <el-option
                        v-for="user in users"
                        :key="user.id"
                        :label="`${user.firstName} ${user.lastName}`"
                        :value="user.id"
                      />
                    </el-select>
                  </el-form-item>
                </div>
                <div>
                  <el-form
                    :model="ruleForm"
                    :rules="rules"
                    ref="ruleForm"
                    label-width="120px"
                    class="subsecuente"
                    label-position="top"
                  >
                  <el-form-item prop="region">
                    <p style="margin: 0;">Tipo De Cita</p>
                    <el-select
                      v-model="cita"
                      placeholder="Select"
                      >
                        <el-option
                          v-for="item in citaTipo"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        />
                      </el-select>
                    </el-form-item>
                    <!-- <el-form-item v-if="cita === 'Consulta General'">
                      <p style="margin: 0;">Médico</p>
                      <el-select v-model="medico" placeholder="Select">
                        <el-option
                          v-for="item in medicos"
                          :key="item.id"
                          :label="item.firstName + ' ' + item.lastName"
                          :value="item.id"
                        />
                      </el-select>
                    </el-form-item> -->
                    <el-form-item
                      v-if="cita === 'Especialidad'"
                    >
                      <p style="margin: 0;">Especialidad</p>
                      <el-select
                      v-model="valueEspecialidad"
                      placeholder="Select"
                      >
                        <el-option
                          v-for="item in specialty"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                        />
                      </el-select>
                    </el-form-item>
                    <el-form-item
                      prop="region"
                      v-if="cita === 'Consulta General'">
                      <p style="margin: 0;">Procedimientos de Consulta General</p>
                      <el-select
                        style="width: 60%;"
                        v-model="procedimientoConsultaGeneral"
                        placeholder="Select"
                      >
                        <el-option
                          v-for="item in options.general"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        />
                      </el-select>
                    </el-form-item>
                    <el-form-item
                      prop="region"
                      v-if="valueEspecialidad"
                    >
                      <p style="margin: 0;">Procedimiento de {{ nameEspecialidad }}</p>
                      <el-select
                        style="width: 60%;"
                        v-model="procedimiento"
                        placeholder="Select"
                      >
                        <el-option
                          v-for="item in options[nameEspecialidad]"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        />
                      </el-select>
                    </el-form-item>
                  </el-form>
                </div>
                <el-form-item
                  v-if="false"
                  prop="newSocialDate"
                  label="¿Desea agendar cita con Trabajo Social?"
                  style="width: 40%!important;">
                  <el-select v-model="socialSchedule">
                    <el-option
                      v-for="item in newSocialDate"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="Comentarios adicionales del paciente"
                  prop="comments"
                  style="width:100%;">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 4, maxRows: 4}"
                      v-model="form.comments"
                      style="width: 100%!important;"/>
                </el-form-item>
                <el-form-item style="width: 100%!important;margin-bottom: 0px!important">
                  <el-button
                    @click="beforeSubmit"
                    style="
                      background-color: #6a41bdb4;
                      color:#fff;float:right;
                      margin-top: 15px;
                      width: 200px;
                      "
                    >
                    {{btnText}}
                  </el-button>
                </el-form-item>
              </el-form>
            </el-main>
          </el-container>
        </div>
        <div :class="right">
          <div class="calendario" v-if="cita === 'Especialidad'">
            <h4> Especialidad </h4>
            <Calendar
              v-on:selected-hour="selectedHour"
              v-on:selected-date="selectedDate"
              :disabledsEspeciality="reservedHoursProcedure"
            />
          </div>
          <div class="calendario" v-if="cita === 'Consulta General'">
            <h4> Consulta General </h4>
            <Calendar
              v-on:selected-hour="selectedHour"
              v-on:selected-date="selectedDate"
              :disableds="reservedHours"
            />
          </div>
          <div v-if="procedimiento" class="card-subsecuente">
            <!-- <strong>Citas agendadas {{cantidadCitas}}</strong> -->
            <el-button @click="contadorOcurrenciasHoras()">Mostrar Cantidad Citas</el-button>
            <el-dialog
              title="Horarios Agendados"
              :visible.sync="dialogVisible"
              width="30%"
              style="border-radius: 20px;"
            >
                <div
                  class="infinite-list"
                  style="
                    overflow:auto;
                    font-size:18px;
                    border-radius: 10px;
                  "
                >
                  <div
                    style="
                      display:flex;
                      justify-content: column;
                      flex-direction: column;
                      "
                  >
                    <div
                      style="
                        display:flex;
                        flex-direction:row;
                        justify-content:space-around;
                        background-color: #6a41bdb6;
                        color: #fff;
                      "
                    >
                      <strong>Hora   </strong>
                      <strong>Cantidad</strong>
                    </div>
                    <div
                      v-for="(item, index) in countCitas"
                      :key="index"
                      class="infinite-list">
                      <div
                        style="
                          display:flex;
                          flex-direction:row;
                          justify-content:space-around;
                        "
                      >
                        <strong>{{index}}</strong>
                        <strong>{{countCitas[index]}}</strong>
                      </div>
                    </div>
                  </div>
                </div>
              <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">Cancel</el-button>
              </span>
            </el-dialog>
          </div>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import Title2 from '../../../components/Title2';
import Calendar from '../../../components/Calendar';
import optionsMixin from '../mixins/options';

export default {
  data() {
    return {
      nameEspecialidad: null,
      // languages: lang,
      countCitas: [],
      dialogVisible: false,
      cita: null,
      citaTipo:
      [
        {
          value: 'Consulta General',
          label: 'Consulta General',
        },
        {
          value: 'Especialidad',
          label: 'Especialidad',
        },
      ],
      medicos: [],
      medico: null,
      valueEspecialidad: null,
      procedimientoConsultaGeneral: null,
      procedimiento: null,
      especialidad: [],
      costoOptions: [
        {
          label: 'Con Costo',
          value: 'c/costo',
        },
        {
          label: 'Sin Costo',
          value: 'S/costo',
        },
      ],
      cantidadCitas: [],
      costo: null,
      value: '',
      specialty: [],
      roles: [{ id: 'MD', name: 'MÉDICO' }],
      users: [],
      reservedHours: [],
      reservedHoursProcedure: [],
      selectedRole: 'MD',
      right: 'right',
      btnText: 'AGENDAR',
      title: 'CITA SUBSECUENTE',
      label: 'Por favor llene los datos para agendar una cita nueva',
      hideButton: false,
      disabled: true,
      language: 'es',
      date: null,
      hour: null,
      loading: false,
      loadingService: null,
      initialForm: {},
      form: {
        userId: null,
        firstName: '',
        lsatName: '',
        birthdate: '',
        curp: '',
        phone: '',
        extraPhone: '',
        cellPhone: '',
        comments: '',
        sex: '',
      },
      ruleForm: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: '',
      },
      citaHora: [],
      socialSchedule: false,
      newSocialDate: [
        {
          value: true,
          label: 'SI',
        },
        {
          value: false,
          label: 'NO',
        },
      ],
      rules: {
        userId: [
          {
            required: true,
            message: 'El campo no puede estar vacío.',
            trigger: 'blur',
          },
          {
            type: 'number',
            message: 'Formato no válido',
            trigger: 'onchange',
          },
        ],
        firstName: [
          {
            required: true,
            message: 'El campo es requerido',
            trigger: 'blur',
          },
        ],
        lastName: [
          {
            required: true,
            message: 'El campo es requerido',
            trigger: 'blur',
          },
        ],
        birthdate: [
          {
            required: true,
            message: 'El campo es requerido',
            trigger: 'blur',
          },
        ],
        comments: [
          {
            required: false,
          },
        ],
      },
    };
  },
  mixins: [optionsMixin],
  components: {
    Title2,
    Calendar,
    // Datepicker,
  },
  computed: {
    userId() {
      return this.medico;
    },
    especialistaId() {
      return this.valueEspecialidad;
    },
  },
  methods: {
    selectedHour(value) {
      this.hour = value;
    },
    contadorOcurrenciasHoras() {
      this.dialogVisible = true;
      const contador = {};
      for (let i = 0; i < this.cantidadCitas.length; i++) {
        const element = this.cantidadCitas[i].time;
        contador[element] = contador[element] ? contador[element] + 1 : 1;
      }
      this.countCitas = contador;
    },
    selectedDate(value) {
      this.date = value;
    },
    beforeSubmit() {
      this.$refs.refId.validate((isValid) => {
        if (isValid && this.date && this.hour) {
          this.submit();
        } else {
          this.$message('Verifique las fechas en el calendario');
        }
      });
    },
    async submit() {
      try {
        this.loading = true;
        // Get form vars
        const {
          comments,
          userId,
        } = this.form;
        const { id: patientId } = this.$route.params;
        const type = 'CITA SUBSECUENTE';
        if (this.cita === 'Especialidad') {
          const response = await this.axios.post(`patient/subsecuente/especialidad/${patientId}`, {
            type,
            location: null,
            date: this.date,
            time: this.hour,
            specialityId: this.valueEspecialidad,
            procedure: this.procedimiento,
            subject: 'Especialidad',
            userId,
            comments,
          });
          if (response) {
            const message = 'Se agendó cita subsecuente';
            await this.axios.post(`patient/changelog/${patientId}`, {
              title: 'Agenda',
              message,
            });
            this.$notify({
              type: 'success',
              message,
            });
            this.loading = false;
            let path = '/';
            if (this.socialSchedule && patientId) {
              path = `/admin/appointments/social/${patientId}`;
            }
            setTimeout(() => {
              this.$router.replace({ path });
            });
          }
        }
        if (this.cita === 'Consulta General') {
          const response = await this.axios.post(`patient/subsecuente/medico/${patientId}`, {
            type,
            location: null,
            date: this.date,
            time: this.hour,
            specialityId: null,
            procedure: this.procedimientoConsultaGeneral,
            subject: 'Médico',
            userId: this.medico,
            comments,
          });
          if (response) {
            const message = 'Se agendó cita subsecuente';
            await this.axios.post(`patient/changelog/${patientId}`, {
              title: 'Agenda',
              message,
            });
            this.$notify({
              type: 'success',
              message,
            });
            this.loading = false;
            let path = '/';
            if (this.socialSchedule && patientId) {
              path = `/admin/appointments/social/${patientId}`;
            }
            setTimeout(() => {
              this.$router.replace({ path });
            });
          }
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$errorNotify(error);
      }
    },
    async getData() {
      try {
        this.loading = true;
        const { id: patientId } = this.$route.params;
        const response = await this.axios.get(`/patient/info/${patientId}`);
        this.form = {
          ...this.form,
          ...response.data,
        };
        this.initialForm = JSON.parse(JSON.stringify(this.form));
        this.loading = false;
      } catch (error) {
        // do something
        this.loading = false;
      }
    },
    // async getUsers() {
    //   try {
    //     this.loading = true;
    //     const response = await this.axios.get(`patient/appointment/users/${this.selectedRole}`);
    //     const { data } = response;
    //     if (Array.isArray(data)) {
    //       this.medicos = data;
    //     }
    //     this.loading = false;
    //   } catch (error) {
    //     this.$errorNotify(error);
    //     this.loading = false;
    //   }
    // },
    async getSpeciality() {
      try {
        this.loading = true;
        const request = await this.axios.get('/patient/specialty/all/v2');
        const { data } = request;
        if (request) {
          data.forEach((element) => {
            this.specialty.push({ id: element.id, name: element.name });
          });
        }
        this.loading = false;
      } catch (error) {
        this.$errorNotify(error);
        this.loading = false;
      }
    },
    async getReservedHours() {
      try {
        this.loading = true;
        if (this.date) {
          const response = await this.axios(`patient/subsecuente/md/reserved-schedules?date=${this.date}`);
          const { data } = response;
          if (Array.isArray(data) && data.length > 0) {
            this.reservedHours = data;
          }
        }
        this.loading = false;
      } catch (error) {
        this.$errorNotify(error);
        this.loading = false;
      }
    },
    async getReservedHoursProcedure() {
      try {
        this.loading = true;
        if (this.date) {
          const response = await this.axios(`patient/subsecuente/esp/reserved-schedules/${this.valueEspecialidad}?date=${this.date}`);
          const { data } = response;
          this.cantidadCitas = data;
          for (let index = 0; index < data.length; index++) {
            this.citaHora[index] = data[index].time;
          }
          if (Array.isArray(data) && data.length > 0) {
            this.reservedHoursProcedure = data;
            const prereserved = [];
            for (let index = 0; index < data.length; index++) {
              // this.reservedHoursProcedure[index] = data[index].time;
              const elemento = data[index].time;
              prereserved[elemento] = prereserved[elemento] ? prereserved[elemento] + 1 : 1;
            }
          }
        }
        this.loading = false;
      } catch (error) {
        this.$errorNotify(error);
        this.loading = false;
      }
    },
  },
  async created() {
    await this.getData();
    if (this.selectedRole) {
      // this.getMedicos();
      // this.getSpecialist();
      await this.getSpeciality();
    }
  },
  watch: {
    loading(val) {
      if (val && !this.loadingService) {
        this.loadingService = this.$loading({
          fullscreen: true,
          lock: true,
          text: 'Cargando',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)',
        });
      } else if (this.loadingService) {
        this.loadingService.close();
        this.loadingService = null;
      }
    },
    selectedRole(val) {
      if (val) {
        this.form.userId = null;
        if (this.cita === 'Especialidad') {
          this.reservedHoursProcedure = [];
          this.getReservedHoursProcedure();
        }
        if (this.cita === 'Consulta General') {
          this.reservedHours = [];
          this.getReservedHours();
        }
        this.getUsers();
      }
    },
    cita(val) {
      if (val) {
        if (this.cita === 'Especialidad') {
          this.reservedHoursProcedure = [];
          this.getReservedHoursProcedure();
        }
        if (this.cita === 'Consulta General') {
          this.reservedHours = [];
          this.getReservedHours();
        }
      }
    },
    especialistaId(val) {
      if (val) {
        this.reservedHoursProcedure = [];
        this.getReservedHoursProcedure();
      }
    },
    date(val) {
      if (val) {
        if (this.cita === 'Especialidad') {
          this.reservedHoursProcedure = [];
          this.getReservedHoursProcedure();
        }
        if (this.cita === 'Consulta General') {
          this.reservedHours = [];
          this.getReservedHours();
        }
      }
    },
    async valueEspecialidad() {
      const name = this.specialty.find((element) => element.id === this.valueEspecialidad);
      this.nameEspecialidad = name.name;
      this.reservedHoursProcedure = [];
      await this.getReservedHoursProcedure();
    },
  },
};
</script>

<style lang="scss" scoped>
div.disabled
{
  pointer-events: none;
  user-select: none;
  opacity: 0.75;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  border-radius: 5px;
}
::-webkit-scrollbar-thumb {
  background: #C2BEBF;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background: #B2AEAA;
}
.row {
  display: flex;
  flex-direction: row;
}
.subsecuente {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px;
  margin-left: 8px;
}
.row-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.el-row {
  height: 100%;
  .el-col {
    width:100%;
    height: 100%;
    .profile {
      padding-top: 8px;
      height: 100%;
      width: 100%;
      .left {
        width: 70%;
        .el-container {
          background-color: #FFF!important;
          height: 100%!important;
          min-height: 0%;
          .el-main {
            margin: 0px;
            .el-form {
              .el-form-item {
                width: 50%;
                margin-bottom: 1px!important;
                .el-form-item__content {
                  width: 90%!important;
                  .el-input {
                    width: 100%!important;
                  }
                }
              }
            }
          }
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        height: 100%;
        width: 30%;
        background-color: rgb(255, 255, 255);
        overflow-y: auto;

        .card-subsecuente {
          width: 72%;
          color:#6a41bdb6;;
          padding: 15px;
          background-color: #fff;
          text-align: center;
          .el-button {
            color: #fff;
            background-color: #6a41bdb6;
          }
        }
      }
    }
  }
}
</style>
