export default {
  data() {
    return {
      options: {
        general: [
          {
            label: 'VALORACION PREOPERATORIA',
            value: 'VALORACION PREOPERATORIA',
          },
          {
            label: 'YAG - LASER',
            value: 'YAG - LASER',
          },
          {
            label: 'REV-YAG-LASER',
            value: 'REV-YAG-LASER',
          },
          {
            label: 'RETOQUE YAG- LASER',
            value: 'RETOQUE YAG- LASER',
          },
          {
            label: 'REV- OP- CATARATA',
            value: 'REV- OP- CATARATA',
          },
          {
            label: 'REV- OP - PTERIGION',
            value: 'REV- OP - PTERIGION',
          },
          {
            label: 'ULTRASONIDO',
            value: 'ULTRASONIDO',
          },
          {
            label: 'RECETA DE LENTES ',
            value: 'RECETA DE LENTES ',
          },
        ],
        Retina: [
          {
            label: 'CONSULTA RETINA',
            value: 'CONSULTA RETINA',
          },
          {
            label: 'PANFOTOCUAGULACION',
            value: 'PANFOTOCUAGULACION',
          },
          {
            label: 'REV PANFOTOCUAGULACION',
            value: 'REV PANFOTOCUAGULACION',
          },
          {
            label: 'REV ANTIANGIOGENICO',
            value: 'REV ANTIANGIOGENICO',
          },
          {
            label: 'ANTIANGIOGENICO',
            value: 'ANTIANGIOGENICO',
          },
          {
            label: 'REV - OP -RETINA',
            value: 'REV - OP -RETINA',
          },
          {
            label: 'ULTRASONIDO',
            value: 'ULTRASONIDO',
          },
        ],
        Glaucoma: [
          {
            label: 'CONSULTA GLAUCOMA',
            value: 'CONSULTA GLAUCOMA',
          },
          {
            label: 'VALORACION PREOPERATORIA',
            value: 'VALORACION PREOPERATORIA',
          },
          {
            label: 'IRIDOTOMIAS ',
            value: 'IRIDOTOMIAS ',
          },
          {
            label: 'REV IRIDOTOMIA',
            value: 'REV IRIDOTOMIA',
          },
          {
            label: 'SLT',
            value: 'SLT',
          },
          {
            label: 'REV SLT',
            value: 'REV SLT',
          },
          {
            label: 'ULTRASONIDO',
            value: 'ULTRASONIDO',
          },
          {
            label: 'REV- OP GLAUCOMA',
            value: 'REV- OP GLAUCOMA',
          },
        ],
        Oftalmopediatría: [
          {
            label: 'CONSULTA PEDIATRIA',
            value: 'CONSULTA PEDIATRIA',
          },
          {
            label: 'VALORACION PREOPERATORIA',
            value: 'VALORACION PREOPERATORIA',
          },
          {
            label: 'REV- OP PEDIATRIA',
            value: 'REV- OP PEDIATRIA',
          },
          {
            label: 'ULTRASONIDO',
            value: 'ULTRASONIDO',
          },
        ],
        Córnea: [
          {
            label: 'CONSULTA CORNEA',
            value: 'CONSULTA CORNEA',
          },
          {
            label: 'VALORACION PREOPERATORIA',
            value: 'VALORACION PREOPERATORIA',
          },
          {
            label: 'REV- OP  TRASPLANTE CORNEA',
            value: 'REV- OP  TRASPLANTE CORNEA',
          },
          {
            label: 'REV-OP QPP',
            value: 'REV-OP QPP',
          },
          {
            label: 'REV-OP DSAEK',
            value: 'REV-OP DSAEK',
          },
          {
            label: 'REV-OP DMEK',
            value: 'REV-OP DMEK',
          },
          {
            label: 'REV- OP ICL',
            value: 'REV- OP ICL',
          },
          {
            label: 'REV- OP -  CROSSLINKING',
            value: 'REV- OP -  CROSSLINKING',
          },
          {
            label: 'ULTRASONIDO',
            value: 'ULTRASONIDO',
          },
        ],
        Orbita: [
          {
            label: 'VALORACION PREOPERATORIA',
            value: 'VALORACION PREOPERATORIA',
          },
          {
            label: 'REV- OP ORBITA',
            value: 'REV- OP ORBITA',
          },
          {
            label: 'INYECCION DE ANTI-INFLAMATORIA',
            value: 'INYECCION DE ANTI-INFLAMATORIA',
          },
          {
            label: 'EXCISION DE LESION BENIGNA SIMPLE',
            value: 'EXCISION DE LESION BENIGNA SIMPLE',
          },
          {
            label: 'EXCISION DE LESION BENIGNA COMPLICADA',
            value: 'EXCISION DE LESION BENIGNA COMPLICADA',
          },
          {
            label: 'BIOPSIA CUTANEA PEQUEÑA ',
            value: 'BIOPSIA CUTANEA PEQUEÑA ',
          },
          {
            label: 'CURETAJE CHALAZION',
            value: 'CURETAJE CHALAZION',
          },
          {
            label: 'DRENAJE DE ABCESO',
            value: 'DRENAJE DE ABCESO',
          },
          {
            label: 'EXPLORACION VIA LAGRIMAL',
            value: 'EXPLORACION VIA LAGRIMAL',
          },
        ],
        Refractiva: [
          {
            label: 'CONSULTA REFRACTIVA',
            value: 'CONSULTA REFRACTIVA',
          },
          {
            label: 'VALORACION',
            value: 'VALORACION',
          },
          {
            label: 'REV OP - REFRACTIVA',
            value: 'REV OP - REFRACTIVA',
          },
          {
            label: 'REV PANFOTOCUAGULACION',
            value: 'REV PANFOTOCUAGULACION',
          },
          {
            label: 'REV OP - LASIK',
            value: 'REV OP - LASIK',
          },
          {
            label: 'REV OP- INTRALASIK',
            value: 'REV OP- INTRALASIK',
          },
          {
            label: 'REV OP -PRK',
            value: 'REV OP -PRK',
          },
        ],
        estudiosGabinete: [
          {
            label: 'CV',
            value: 'CV',
          },
          {
            label: 'OCT',
            value: 'OCT',
          },
          {
            label: 'FAG',
            value: 'FAG',
          },
          {
            label: 'FOTOS',
            value: 'FOTOS',
          },
          {
            label: 'PAQUIS',
            value: 'PAQUIS',
          },
          {
            label: 'IOL',
            value: 'IOL',
          },
          {
            label: 'VISANTA',
            value: 'VISANTA',
          },
          {
            label: 'ORBSCAN',
            value: 'ORBSCAN',
          },
          {
            label: 'OPD',
            value: 'OPD',
          },
          {
            label: 'PENTACAM',
            value: 'PENTACAM',
          },
          {
            label: 'ATLAS',
            value: 'ATLAS',
          },
          {
            label: 'CONT/CELULAS',
            value: 'CONT/CELULAS',
          },
          {
            label: 'INMERSION',
            value: 'INMERSION',
          },
          {
            label: 'PAM',
            value: 'PAM',
          },
          {
            label: 'NIDEK',
            value: 'NIDEK',
          },
        ],
        'Óptica (lentes)': [
          {
            label: 'LENTES',
            value: 'LENTES',
          },
        ],
      },
      otheroptions: {
        especialidad: [
          {
            label: 'RETINA',
            value: 'RETINA',
          },
          {
            label: 'GLAUCOMA',
            value: 'GLAUCOMA',
          },
          {
            label: 'PEDIATRIA',
            value: 'PEDIATRIA',
          },
          {
            label: 'CORNEA',
            value: 'CORNEA',
          },
          {
            label: 'ORBITA',
            value: 'ORBITA',
          },
          {
            label: 'REFRACTIVA',
            value: 'REFRACTIVA',
          },
        ],
      },
    };
  },
};
